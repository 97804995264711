import { plainBtnStyles } from '@/buttons/Plain';
import { css } from '@emotion/react';
import { theme } from './styles/GlobalStyles';
import { lgTxt } from './styles/Typography';

const dynamicStyles = css`
    &[aria-current='page'] {
        color: ${theme.colors.blue4};
    }

    &.current-nav-category {
        color: ${theme.colors.blue4};

        > svg path {
            fill: ${theme.colors.blue4};
        }
    }

    &[data-mobile-expanded],
    &[aria-expanded='true'] {
        > svg {
            transform: rotate(180deg);
        }
    }

    @media (pointer: fine) {
        :hover {
            color: ${theme.colors.blue5};

            > svg path {
                fill: ${theme.colors.blue5};
            }
        }

        :focus-visible {
            color: ${theme.colors.blue5};

            > svg path {
                fill: ${theme.colors.blue5};
            }
        }
    }
`;

export const navLinkStyles = css`
    ${dynamicStyles};
    ${lgTxt};
    text-transform: capitalize;
    color: ${theme.colors.gray4};
    font-weight: 500;
    letter-spacing: 0.1px;
    transition: color 0.3s ease-in-out;
    margin-bottom: 40px;

    @media (min-width: 840px) {
        margin-bottom: 0 !important;
        margin-right: 32px;
    }

    @media (min-width: 1024px) {
        margin-right: 64px;
    }
`;

export const subNavLinkStyles = css`
    ${navLinkStyles};

    @media (min-width: 840px) {
        padding: 8px 16px;
        display: block;
        margin-right: 0 !important;
        border-bottom: 1px solid ${theme.colors.gray2};
    }
`;

export const navBtnStyles = css`
    ${plainBtnStyles};
    ${navLinkStyles};

    > svg {
        margin-left: 20px;
        transition: transform 0.3s ease-in-out;
        flex-shrink: 0;

        path {
            transition: fill 0.3s ease-in-out;
            fill: ${theme.colors.gray4};
        }
    }
`;
