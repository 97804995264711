export const siteInfo = {
    phone: {
        text: '(970) 708-7627',
        link: 'tel:+19707087627',
    },
    email: {
        text: 'info@smartazminerals.com',
        link: 'mailto:info@smartazminerals.com',
    },
    address: {
        text: '215 5th Ave, Ouray, CO 81427',
        link: 'https://goo.gl/maps/zGDWR7nvMYNHYR2LA',
    },
    social: {
        facebook: 'https://www.facebook.com/SmartAZMinerals/',
        instagram: 'https://instagram.com/smartazminerals',
        twitter: 'https://twitter.com/smartazminerals',
        pinterest: 'https://pinterest.com/smartazminerals/',
        youtube: 'https://youtube.com/@smarta-zminerals2108',
        tiktok: 'https://www.tiktok.com/@smartazminerals',
    },
};

export type NavLinksTypes = { text: string; link: string; links?: NavLinksTypes }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'shop all',
        link: '/collections/all/',
    },
    {
        text: 'about us',
        link: '',
        links: [
            {
                text: 'our story',
                link: '/about-us/our-story/',
            },
            {
                text: 'our process',
                link: '/about-us/our-process/',
            },
            {
                text: 'benefits',
                link: '/about-us/benefits/',
            },
            {
                text: 'brochure',
                link: '/about-us/brochure/',
            },
            {
                text: 'liquid mineral FAQ’s',
                link: '/liquid-mineral-faqs/',
            },
        ],
    },
    {
        text: 'blog',
        link: '/blogs/articles/',
    },
    {
        text: 'contact us',
        link: '/contact-us/',
    },
];

const flatLinks = (() => {
    const flat: NavLinksTypes = [];

    const pushFlat = (arr: NavLinksTypes) =>
        arr.forEach(data => {
            if (!data.links) flat.push(data);
            else {
                flat.push({
                    text: data.text,
                    link: data.link,
                });
                pushFlat(data.links);
            }
        });

    pushFlat(navLinks);
    return flat;
})();

export const footerNavLinks: NavLinksTypes = [
    {
        text: 'liquid mineral supplements',
        link: getNavLink('shop all'),
    },
    {
        text: 'product collections',
        link: '/collections/',
    },
    {
        text: 'our story',
        link: getNavLink('our story'),
    },
    {
        text: 'our process',
        link: getNavLink('our process'),
    },
    {
        text: 'benefits',
        link: getNavLink('benefits'),
    },
    {
        text: 'blog',
        link: getNavLink('blog'),
    },
    {
        text: 'fAQ’s',
        link: getNavLink('liquid mineral FAQ’s'),
    },
    {
        text: 'disclaimer',
        link: '/policies/disclaimer/',
    },
    {
        text: 'terms of service',
        link: '/policies/terms-of-service/',
    },
    {
        text: 'refund policy',
        link: '/policies/refund-policy/',
    },
    {
        text: 'shipping policy',
        link: '/policies/shipping-policy/',
    },
    {
        text: 'privacy policy',
        link: '/policies/privacy-policy/',
    },
];

export function getNavLink(text: string) {
    const linkObj = flatLinks.find(data => data.text.toLowerCase() === text.toLowerCase());

    if (linkObj) return linkObj.link;
    else return '';
}
