import useLocationData from '@s/hooks/useLocationData';
import { graphql, useStaticQuery } from 'gatsby';
import { Fragment } from 'react';
import { SwiperSlide } from 'swiper/react';
import { ProductCard, ProductDataType } from './ProductCard';
import { ProductsSection } from './ProductsSection';

export const PopularProducts = () => {
    const {
        products: { nodes },
    } = useStaticQuery(graphql`
        query PopularProducts {
            products: allShopifyProduct(
                filter: {
                    shopifyId: {
                        in: [
                            "gid://shopify/Product/4397008814132" # boron
                            "gid://shopify/Product/6597774278808" # ez-8
                            "gid://shopify/Product/4397129170996" # zinc
                            "gid://shopify/Product/4397124812852" # silver
                            "gid://shopify/Product/4397060390964" # magnesium
                            "gid://shopify/Product/4397116850228" # potassium
                        ]
                    }
                }
                sort: { title: ASC }
            ) {
                nodes {
                    title
                    variants {
                        storefrontId
                        price
                    }
                    handle
                    featuredImage {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 345, height: 450)
                            }
                        }
                    }
                }
            }
        }
    `);

    const { pageTitle, category } = useLocationData();

    if (
        pageTitle === '' ||
        category === 'collections' ||
        category === 'account' ||
        category === 'policies'
    )
        return <Fragment />;
    return (
        <ProductsSection title="Most Popular" tagline="our products">
            {nodes.map((product: ProductDataType, i: number) => (
                <SwiperSlide key={i}>
                    <ProductCard {...product} />
                </SwiperSlide>
            ))}
        </ProductsSection>
    );
};
