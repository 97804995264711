import styled from '@emotion/styled';
import { ShopifyContext } from '@s/context/ShopifyContext';
import { Fragment, useContext, useEffect, useState } from 'react';

const Toast = styled.p`
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blue4};
    width: max-content;
    position: fixed;
    top: 100px;
    right: 32px;
    border-radius: 24px;
    padding: 8px 16px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 200;

    @keyframes showing {
        0% {
            transform: translateX(200px);
            opacity: 0;
        }
        50% {
            transform: translateX(0);
            opacity: 1;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes hiding {
        50% {
            transform: translateX(200px);
            opacity: 0;
        }
        100% {
            transform: translateX(200px);
            opacity: 0;
        }
    }

    &.hiding {
        animation: hiding 1s ease;
    }

    &.showing {
        animation: showing 1s ease-out;
    }
`;

export const CartToast = () => {
    const { loading, addedToCart } = useContext(ShopifyContext);

    const [animation, setAnimation] = useState('');

    const show = loading || addedToCart;
    const [visible, setVisible] = useState(show);

    useEffect(() => {
        if (show) {
            setVisible(true);
        }
        const timeout = setTimeout(() => {
            setAnimation('');
            setVisible(show);
        }, 900);
        setAnimation(show ? 'showing' : 'hiding');
        return () => clearTimeout(timeout);
    }, [show]);

    return visible ? (
        <Toast className={animation}>{addedToCart ? 'Added to cart' : 'Updating…'}</Toast>
    ) : (
        <Fragment />
    );
};
