import { Input, Label, Thanks } from '@/FormComponents';
import { PrimaryBtn } from '@/buttons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { useState } from 'react';
import { flexCenter, ml, mr, px, py } from './styles/classes';

const Section = styled.section`
    ${py};
    ${px};
    background-color: ${({ theme }) => theme.colors.blue1};

    @media (min-width: 1024px) {
        ${flexCenter};
        position: relative;

        ::before {
            ${ml};
            ${mr};
            content: '';
            background-color: ${({ theme }) => theme.colors.blue4};
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 56%;
            z-index: 1;
            border-radius: 32px;
            max-width: 1312px;
        }
    }

    @media (min-width: 1440px) {
        ::before {
            margin: 0 auto;
        }
    }
`;

const Box = styled.div`
    max-width: 550px;
    z-index: 2;

    > p {
        :first-of-type {
            margin: 0;
        }

        :last-of-type {
            margin: 0;
            font-size: 1rem;
        }
    }

    > h2 {
        color: ${({ theme }) => theme.colors.blue7};
        margin: 12px 0;
    }

    @media (min-width: 1024px) {
        ${ml};
        margin-right: ${({ theme }) => theme.spacing.lg};

        > p:last-of-type {
            color: ${({ theme }) => theme.colors.white};
        }

        > h2 {
            margin-bottom: 24px;
        }
    }
`;

const Form = styled.form<{ submit: boolean }>`
    position: relative;
    margin: 24px 0;

    > input {
        padding: 16px 20px;
        border-radius: 40px;
        margin-bottom: 12px;
        max-width: 380px;
    }

    > div:last-of-type {
        background-color: ${({ theme }) => theme.colors.blue1};
        align-items: flex-start;
        padding: 0 !important;

        > p {
            margin: 0;
        }
    }

    ${({ submit }) =>
        submit &&
        css`
            select,
            textarea,
            input,
            > button {
                pointer-events: none;
                visibility: hidden;
            }
        `};

    @media (min-width: 1024px) {
        margin-bottom: 0;

        > div:last-of-type {
            background-color: ${({ theme }) => theme.colors.blue4};

            > p {
                color: ${({ theme }) => theme.colors.white};
            }
        }
    }
`;

const Wrapper = styled.div`
    max-width: max-content;
    position: relative;
    z-index: 2;

    > .gatsby-image-wrapper {
        border-radius: 128px 16px;
        opacity: 0.9999; //safari overflow bug fix
    }

    ::before {
        content: '';
        position: absolute;
        top: 25.58%;
        left: -16px;
        right: -16px;
        bottom: -100px;
        background-color: ${({ theme }) => theme.colors.blue4};
        border-radius: 48px;
    }

    @media (min-width: 1024px) {
        ${mr};

        ::before {
            left: -24px;
            right: -24px;
        }

        > .gatsby-image-wrapper {
            border-radius: 250px 15px;
        }

        ::before {
            content: none;
        }
    }
`;

export const Newsletter = () => {
    const [submit, setSubmit] = useState(false);
    const [sending, setSending] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setSending(true);

        const data = Object.fromEntries(new FormData(e.currentTarget));

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmit(true);
        setSending(false);
        e.target.reset();
    };

    return (
        <Section>
            <Box>
                <p className="tagline">Newsletter</p>
                <h2>Take charge of your health today</h2>
                <p>
                    Sign up today for a chance to avail deals and promos, we don’t spam! Just the
                    good stuff.
                </p>
                <Form onSubmit={handleSubmit} submit={submit}>
                    <Label htmlFor="newsletterEmail">Your email</Label>
                    <Input
                        type="email"
                        id="newsletterEmail"
                        name="newsletterEmail"
                        placeholder="Enter your email"
                    />
                    <PrimaryBtn disabled={sending}>
                        {sending ? 'Sending...' : 'Subscribe'}
                    </PrimaryBtn>
                    <Thanks submit={submit}>
                        <p>Thank you for subscribing.</p>
                    </Thanks>
                </Form>
            </Box>
            <Wrapper>
                <StaticImage
                    src="../../../assets/images/layout/newsletter.jpg"
                    alt="woman with hands up in the air"
                    width={567}
                    height={512}
                />
            </Wrapper>
        </Section>
    );
};
