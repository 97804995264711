import { CLink } from '@/CLink';
import { ReactComponent as CopyrightIcon } from '@a/icons/copyright.svg';
import { ReactComponent as EmailIcon } from '@a/icons/email.svg';
import { ReactComponent as FacebookIcon } from '@a/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '@a/icons/instagram.svg';
import { ReactComponent as AddressIcon } from '@a/icons/location.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { ReactComponent as PinterestIcon } from '@a/icons/pinterest.svg';
import { ReactComponent as TiktokIcon } from '@a/icons/tiktok.svg';
import { ReactComponent as TwitterIcon } from '@a/icons/twitter.svg';
import { ReactComponent as YoutubeIcon } from '@a/icons/youtube.svg';
import styled from '@emotion/styled';
import { LogoLink } from './Nav';
import { footerNavLinks, siteInfo } from './NavLinks';
import { flexCenter, flexSpace, flexStart, npx, px } from './styles/classes';

const Section = styled.section`
    ${px};
    background-color: ${({ theme }) => theme.colors.blue7};
    color: ${({ theme }) => theme.colors.white};
    padding-top: 100px;

    @media (min-width: 1024px) {
        padding-top: 200px;
        margin-top: -150px;
        position: relative;
    }

    @media (min-width: 1440px) {
        padding-top: 240px;
        margin-top: -180px;
    }
`;

const Contact = styled.div`
    ${flexCenter};
    gap: 24px;
    flex-wrap: wrap;

    > a {
        ${flexStart};
        align-items: flex-end;
        background-color: ${({ theme }) => theme.colors.blue6};
        padding: 16px;
        border-radius: 16px;
        width: 100%;
        max-width: 418px;

        :hover,
        :focus {
            > div > p:last-of-type {
                color: ${({ theme }) => theme.colors.blue4};
            }
        }

        > svg {
            flex-shrink: 0;
        }

        > div {
            margin-left: 24px;

            > p {
                font-size: 1.25rem;
                line-height: 140%;
                color: ${({ theme }) => theme.colors.white};
                transition: color 0.3s ease-in-out;
                word-break: break-all;

                :first-of-type {
                    margin: 0;
                }

                :last-of-type {
                    margin: 0;
                    font-weight: 500;
                }
            }
        }
    }

    @media (min-width: 1280px) {
        max-width: 1312px;
        margin: 0 auto;

        > a {
            justify-content: center;
            padding: 21px 26px;
        }
    }
`;

const Flex = styled.div`
    max-width: 1312px;
    margin: 0 auto;

    @media (min-width: 1024px) {
        ${flexSpace};
        align-items: flex-start;
        margin: 64px auto;
        gap: 24px;
    }
`;

const Info = styled.div`
    max-width: 700px;
    margin: 24px 0;

    > a:first-of-type {
        background-color: ${({ theme }) => theme.colors.blue1};
        border-radius: 6px;
        max-width: max-content;
    }

    > p {
        margin: 24px 0;
    }

    > div:last-of-type {
        ${flexStart};
        align-items: center;
        gap: 16px;

        > a {
            ${flexCenter};
            width: 40px;
            height: 40px;
            background-color: ${({ theme }) => theme.colors.blue4};
            border-radius: 50%;
            transition: background-color 0.3s ease-in-out;

            :hover,
            :focus {
                background-color: ${({ theme }) => theme.colors.blue8};
            }
        }
    }

    @media (min-width: 1024px) {
        margin: 0;

        > p {
            margin: 32px 0;
        }
    }
`;

const Links = styled.div`
    flex-shrink: 0;

    :last-of-type {
        margin: 24px 0 48px;
    }

    > h4 {
        margin: 0 0 24px;
        text-transform: capitalize;
    }

    > ul {
        margin: 0;

        > li {
            :not(:last-of-type) {
                margin-bottom: 16px;
            }

            > a {
                color: ${({ theme }) => theme.colors.white};
                text-transform: capitalize;
                transition: color 0.3s ease-in-out;

                :hover,
                :focus {
                    color: ${({ theme }) => theme.colors.blue4};
                }
            }
        }
    }

    @media (min-width: 1024px) {
        :last-of-type {
            margin: 0;
        }
    }
`;

const Copyright = styled.p`
    ${npx};
    ${flexCenter};
    font-size: 1rem;
    padding: 16px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.white};
    margin-top: 0;
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.gray1};

    > svg {
        margin-right: 4px;
        flex-shrink: 0;
    }

    @media (min-width: 1024px) {
        padding: 32px 0;
    }
`;

export const Footer = () => {
    return (
        <Section>
            <Contact>
                <CLink to={siteInfo.phone.link}>
                    <PhoneIcon />
                    <div>
                        <p>Phone Number</p>
                        <p>{siteInfo.phone.text}</p>
                    </div>
                </CLink>
                <CLink to={siteInfo.email.link}>
                    <EmailIcon />
                    <div>
                        <p>Email Address</p>
                        <p>{siteInfo.email.text}</p>
                    </div>
                </CLink>
                <CLink to={siteInfo.address.link}>
                    <AddressIcon />
                    <div>
                        <p>Office Location</p>
                        <p>{siteInfo.address.text}</p>
                    </div>
                </CLink>
            </Contact>
            <Flex>
                <Info>
                    <LogoLink />
                    <p>
                        Smart A-Z Minerals is determined to provide healthy and safe ionic minerals
                        for our customers. We will use only the highest quality minerals and ultra
                        clean bottling practices in production of our products. We aim to produce
                        cellular available minerals with consistently accurate mineral levels that
                        are pure and clean.
                    </p>
                    <div>
                        <CLink to={siteInfo.social.twitter} aria-label="checkout our twitter page">
                            <TwitterIcon />
                        </CLink>
                        <CLink
                            to={siteInfo.social.instagram}
                            aria-label="checkout our instagram page"
                        >
                            <InstagramIcon />
                        </CLink>
                        <CLink
                            to={siteInfo.social.facebook}
                            aria-label="checkout our facebook page"
                        >
                            <FacebookIcon />
                        </CLink>
                        <CLink
                            to={siteInfo.social.pinterest}
                            aria-label="checkout our pinterest page"
                        >
                            <PinterestIcon />
                        </CLink>
                        <CLink to={siteInfo.social.tiktok} aria-label="checkout our tiktok page">
                            <TiktokIcon />
                        </CLink>
                        <CLink to={siteInfo.social.youtube} aria-label="checkout our youtube page">
                            <YoutubeIcon />
                        </CLink>
                    </div>
                </Info>
                <Links>
                    <h4>Navigate</h4>
                    <ul>
                        {footerNavLinks.slice(0, 6).map((data, i) => (
                            <li key={i}>
                                <CLink to={data.link}>{data.text}</CLink>
                            </li>
                        ))}
                    </ul>
                </Links>
                <Links>
                    <h4>Navigate</h4>
                    <ul>
                        {footerNavLinks.slice(6).map((data, i) => (
                            <li key={i}>
                                <CLink to={data.link}>{data.text}</CLink>
                            </li>
                        ))}
                    </ul>
                </Links>
            </Flex>
            <Copyright>
                <CopyrightIcon /> Copyright {new Date().getFullYear()}. Smart AZ Mineral LLC.
            </Copyright>
        </Section>
    );
};
