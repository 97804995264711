import { CLink } from '@/CLink';
import { Dialog, DialogClose, DialogContent, DialogOpen } from '@/DialogPrimitive';
import { PlainBtn } from '@/buttons';
import { ReactComponent as ChevronIcon } from '@a/icons/nav-chevron.svg';
import { css } from '@emotion/react';
import useLocationData from '@s/hooks/useLocationData';
import useRouteChange from '@s/hooks/useRouteChange';
import useWindowSize from '@s/hooks/useWindowSize';
import { Fragment, useEffect, useState } from 'react';
import { navBtnStyles, subNavLinkStyles } from './NavLinkStyles';
import { NavLinksTypes } from './NavLinks';
import { flexCenter, flexStart } from './styles/classes';

const overlayStyles = css`
    display: none;
`;

const contentStyles = css`
    ${flexCenter};
    flex-direction: column;
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;

    > button:first-of-type {
        margin-left: auto;
        margin-right: auto;
        max-width: max-content;
        display: flex;
    }

    > nav {
        ${flexStart};
        flex-direction: column;
        align-items: center;
        max-height: calc(100% - 50px);
        overflow-y: auto;
    }
`;

type SubNavProps = {
    text: string;
    links: NavLinksTypes;
    setSubDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MobSubNav = ({ text, links, setSubDialogOpen }: SubNavProps) => {
    const [open, setOpen] = useState(false);

    const { width } = useWindowSize();

    useRouteChange(setOpen);

    const { category } = useLocationData();

    useEffect(() => {
        setSubDialogOpen(open);
    }, [open, setSubDialogOpen]);

    return width < 768 ? (
        <Dialog open={open} onOpenChange={setOpen} modal={false}>
            <DialogOpen>
                <PlainBtn
                    aria-label={`open ${text} menu`}
                    css={navBtnStyles}
                    className={category === text ? 'current-nav-category' : ''}
                >
                    {text} <ChevronIcon />
                </PlainBtn>
            </DialogOpen>

            <DialogContent
                contentStyles={contentStyles}
                overlayStyles={overlayStyles}
                title={`${text} navigation menu`}
            >
                <DialogClose>
                    <PlainBtn
                        data-mobile-expanded="true"
                        css={navBtnStyles}
                        className={category === text ? 'current-nav-category' : ''}
                    >
                        {text} <ChevronIcon />
                    </PlainBtn>
                </DialogClose>
                <nav>
                    {links.map((data, i) => (
                        <CLink to={data.link} css={subNavLinkStyles} key={i}>
                            {data.text}
                        </CLink>
                    ))}
                </nav>
            </DialogContent>
        </Dialog>
    ) : (
        <Fragment />
    );
};
