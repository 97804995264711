import { ReactComponent as ArrowIcon } from '@a/icons/right-arrow.svg';
import styled from '@emotion/styled';
import { ElementType, ReactNode } from 'react';
import Plain from './Plain';

const Btn = styled(Plain)`
    padding: 16px 47px;
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.blue6};
    color: ${({ theme }) => theme.colors.white};
    text-transform: uppercase;
    transition: background-color 0.3s ease-in-out;

    > svg {
        margin-left: 4px;
        transform: translateY(-1px);
    }

    :hover {
        background-color: ${({ theme }) => theme.colors.blue8};
    }

    :focus-visible {
        background-color: ${({ theme }) => theme.colors.blue7};
    }
`;

type BtnProps = React.HTMLAttributes<HTMLButtonElement> & {
    as?: ElementType;
    children: ReactNode;
    to?: string;
    disabled?: boolean;
};

export default function PrimaryBtn({ children, ...props }: BtnProps) {
    return (
        <Btn {...props}>
            {children} <ArrowIcon />
        </Btn>
    );
}
