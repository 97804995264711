import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { Slider } from './Slider';
import { py } from './layout/styles/classes';

const Section = styled.div`
    ${py};

    > p {
        padding: 0 16px;
        margin: 0;
        text-align: center;
        color: ${({ theme }) => theme.colors.blue4};
        font-weight: 600;
        text-transform: uppercase;
    }

    > h2 {
        color: ${({ theme }) => theme.colors.blue8};
        margin: 0;
        text-align: center;
        padding: 0 16px;
        text-transform: capitalize;
    }

    > .swiper {
        margin-top: 24px;

        .swiper-slide {
            width: 345px;

            :first-of-type {
                box-sizing: content-box;
                padding-left: 16px;
            }

            :last-of-type {
                padding-right: 16px;
                box-sizing: content-box;
            }
        }
    }

    @media (min-width: 768px) {
        > .swiper .swiper-slide {
            :first-of-type {
                padding-left: 32px;
            }

            :last-of-type {
                padding-right: 32px;
            }
        }
    }

    @media (min-width: 1024px) {
        > .swiper .swiper-slide {
            :first-of-type {
                padding-left: 48px;
            }

            :last-of-type {
                padding-right: 48px;
            }
        }
    }

    @media (min-width: 1280px) {
        > .swiper {
            margin-top: 48px;

            .swiper-slide {
                :first-of-type {
                    padding-left: 64px;
                }

                :last-of-type {
                    padding-right: 64px;
                }
            }
        }
    }
`;

type ProductsSectionProps = {
    title: string;
    tagline: string;
    children: ReactNode;
};

export const ProductsSection = ({ title, tagline, children }: ProductsSectionProps) => {
    const sliderSettings = {
        slidesPerView: 'auto' as const,
        spaceBetween: 8,
        breakpoints: {
            768: {
                spaceBetween: 16,
            },
            1024: {
                spaceBetween: 24,
            },
            1280: {
                spaceBetween: 32,
            },
        },
    };
    return (
        <Section>
            <p>{tagline}</p>
            <h2>{title}</h2>
            <Slider sliderSettings={sliderSettings}>{children}</Slider>
        </Section>
    );
};
