import { css } from '@emotion/react';

export const h1 = css`
    font-size: 3rem;
    line-height: 113%;
    font-weight: 600;

    @media (min-width: 1280px) {
        font-size: 3.75rem;
    }
`;

export const h2 = css`
    font-size: 2.25rem;
    line-height: 108.33%;
    font-weight: 600;
    text-transform: capitalize;

    @media (min-width: 1280px) {
        font-size: 3rem;
    }
`;

export const h3 = css`
    font-size: 1.5rem;
    line-height: 120%;
    font-weight: 600;

    @media (min-width: 1280px) {
        font-size: 2.125rem;
    }
`;

export const h4 = css`
    font-size: 1.25rem;
    line-height: 138%;
    font-weight: 600;

    @media (min-width: 1280px) {
        font-size: 1.625rem;
    }
`;

export const tagline = css`
    color: #41bae3;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1rem;
`;

export const smTxt = css`
    font-size: 0.875rem;

    @media (min-width: 1280px) {
        font-size: 1rem;
    }
`;

export const lgTxt = css`
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.15%;

    @media (min-width: 1280px) {
        font-size: 1.25rem;
    }
`;
