exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-benefits-tsx": () => import("./../../../src/pages/about-us/benefits.tsx" /* webpackChunkName: "component---src-pages-about-us-benefits-tsx" */),
  "component---src-pages-about-us-brochure-tsx": () => import("./../../../src/pages/about-us/brochure.tsx" /* webpackChunkName: "component---src-pages-about-us-brochure-tsx" */),
  "component---src-pages-about-us-our-process-tsx": () => import("./../../../src/pages/about-us/our-process.tsx" /* webpackChunkName: "component---src-pages-about-us-our-process-tsx" */),
  "component---src-pages-about-us-our-story-tsx": () => import("./../../../src/pages/about-us/our-story.tsx" /* webpackChunkName: "component---src-pages-about-us-our-story-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-account-login-tsx": () => import("./../../../src/pages/account/login.tsx" /* webpackChunkName: "component---src-pages-account-login-tsx" */),
  "component---src-pages-account-recover-tsx": () => import("./../../../src/pages/account/recover.tsx" /* webpackChunkName: "component---src-pages-account-recover-tsx" */),
  "component---src-pages-account-register-tsx": () => import("./../../../src/pages/account/register.tsx" /* webpackChunkName: "component---src-pages-account-register-tsx" */),
  "component---src-pages-account-reset-tsx": () => import("./../../../src/pages/account/reset.tsx" /* webpackChunkName: "component---src-pages-account-reset-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-collections-all-tsx": () => import("./../../../src/pages/collections/all.tsx" /* webpackChunkName: "component---src-pages-collections-all-tsx" */),
  "component---src-pages-collections-index-tsx": () => import("./../../../src/pages/collections/index.tsx" /* webpackChunkName: "component---src-pages-collections-index-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-liquid-mineral-faqs-tsx": () => import("./../../../src/pages/liquid-mineral-faqs.tsx" /* webpackChunkName: "component---src-pages-liquid-mineral-faqs-tsx" */),
  "component---src-pages-policies-disclaimer-tsx": () => import("./../../../src/pages/policies/disclaimer.tsx" /* webpackChunkName: "component---src-pages-policies-disclaimer-tsx" */),
  "component---src-pages-policies-privacy-policy-tsx": () => import("./../../../src/pages/policies/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-policies-privacy-policy-tsx" */),
  "component---src-pages-policies-refund-policy-tsx": () => import("./../../../src/pages/policies/refund-policy.tsx" /* webpackChunkName: "component---src-pages-policies-refund-policy-tsx" */),
  "component---src-pages-policies-shipping-policy-tsx": () => import("./../../../src/pages/policies/shipping-policy.tsx" /* webpackChunkName: "component---src-pages-policies-shipping-policy-tsx" */),
  "component---src-pages-policies-terms-of-service-tsx": () => import("./../../../src/pages/policies/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-policies-terms-of-service-tsx" */),
  "component---src-templates-blog-page-article-tsx": () => import("./../../../src/templates/blog-page-article.tsx" /* webpackChunkName: "component---src-templates-blog-page-article-tsx" */),
  "component---src-templates-blog-post-article-tsx": () => import("./../../../src/templates/blog-post-article.tsx" /* webpackChunkName: "component---src-templates-blog-post-article-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

