import styled from '@emotion/styled';
import Plain from './Plain';

export default styled(Plain)`
    padding: 16px 32px;
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.blue4};
    color: ${({ theme }) => theme.colors.white};
    text-transform: uppercase;
    transition: background-color 0.3s ease-in-out;

    > svg {
        margin-right: 4px;
        transform: translateY(-1px);
    }

    :hover {
        background-color: ${({ theme }) => theme.colors.blue5};
    }

    :focus-visible {
        background-color: ${({ theme }) => theme.colors.blue5};
    }
`;
