import { ReactComponent as ArrowIcon } from '@a/icons/right-arrow.svg';
import styled from '@emotion/styled';
import { ElementType, ReactNode } from 'react';
import Plain from './Plain';

const Btn = styled(Plain)`
    padding: 9px 12px;
    color: ${({ theme }) => theme.colors.blue6};
    text-transform: uppercase;
    transition: background-color 0.3s ease-in-out;
    position: relative;
    z-index: 1;
    transition: color 0.3s ease-in-out;

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 42px;
        height: 42px;
        background-color: ${({ theme }) => theme.colors.blue4};
        z-index: -1;
        border-radius: 50%;
        transition: background-color 0.3s ease-in-out;
    }

    > svg {
        margin-right: 5px;
        margin-left: 3px;

        path {
            stroke: ${({ theme }) => theme.colors.blue6};
            transition: stroke 0.3s ease-in-out;
        }
    }

    :hover {
        color: ${({ theme }) => theme.colors.blue4};

        ::before {
            background-color: ${({ theme }) => theme.colors.blue6};
        }

        > svg > path {
            stroke: ${({ theme }) => theme.colors.blue4};
        }
    }

    :focus-visible {
        color: ${({ theme }) => theme.colors.blue4};

        ::before {
            background-color: ${({ theme }) => theme.colors.blue6};
        }

        > svg > path {
            stroke: ${({ theme }) => theme.colors.blue4};
        }
    }
`;

type BtnProps = React.HTMLAttributes<HTMLButtonElement> & {
    as?: ElementType;
    children: ReactNode;
    to?: string;
    disabled?: boolean;
};

export default function ArrowCircleBtn({ children, ...props }: BtnProps) {
    return (
        <Btn {...props}>
            <ArrowIcon /> {children}
        </Btn>
    );
}
