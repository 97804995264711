import { ReactComponent as ArrowIcon } from '@a/icons/right-arrow.svg';
import styled from '@emotion/styled';
import { ElementType, ReactNode } from 'react';
import Plain from './Plain';

const Btn = styled(Plain)`
    > span {
        border-bottom: 2px solid ${({ theme }) => theme.colors.blue4};
        color: ${({ theme }) => theme.colors.blue4};
        text-transform: uppercase;
        transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    }

    > svg {
        margin-left: 7px;

        path {
            stroke: ${({ theme }) => theme.colors.blue4};
            transition: stroke 0.3s ease-in-out;
        }
    }

    :hover {
        > span {
            color: ${({ theme }) => theme.colors.blue6};
            border-color: ${({ theme }) => theme.colors.blue6};
        }

        > svg > path {
            stroke: ${({ theme }) => theme.colors.blue6};
        }
    }

    :focus-visible {
        > span {
            color: ${({ theme }) => theme.colors.blue6};
            border-color: ${({ theme }) => theme.colors.blue6};
        }

        > svg > path {
            stroke: ${({ theme }) => theme.colors.blue6};
        }
    }
`;

type BtnProps = React.HTMLAttributes<HTMLButtonElement> & {
    as?: ElementType;
    children: ReactNode;
    to?: string;
};

export default function TxtBtn({ children, ...props }: BtnProps) {
    return (
        <Btn {...props}>
            <span>{children}</span> <ArrowIcon />
        </Btn>
    );
}
